const restClient = require('nordic/restclient')({
  timeout: 5000,
});
const sitesCurrencyConfigs = require('../config/pricesLanding/sites-currency');
const { getCurrencySymbol } = require('./transforms/currencies');

class CurrencyService {
  static setupCurrencyFormatter = (siteId = 'MLA') => {
    const { locale = 'es-ar', currency = 'ARS' } = sitesCurrencyConfigs[`${siteId}`];

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  static formatNumber = (siteId = 'MLA', number) => {
    const { locale = 'es-ar' } = sitesCurrencyConfigs[`${siteId}`];
    const formatter = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  static currencyStringToNumber = (currencyValue) => {
    let result = '0';

    if (typeof currencyValue === 'string') {
      result = currencyValue.split(',')[0].replace(/[^\d]/g, '');
    }

    return parseInt(result, 10);
  };

  static removeDecimals = (currencyValue) => currencyValue.split(',')[0];

  static getCurrencySymbolBySite(context, siteId) {
    return restClient.get(`/sites/${siteId}`, { context })
      .then(response => response.data)
      .then(data => {
        const { currency } = sitesCurrencyConfigs[`${siteId}`];
        return getCurrencySymbol(data, currency);
      })
      .catch(e => console.log(e));
  }
}

module.exports = CurrencyService;
