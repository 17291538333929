const React = require('react');
const { number } = require('prop-types');

const namespace = 'check-icon';

const CheckIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={namespace}
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M10.182 14.094L17.047 7.229 18.59 8.771 10.182 17.179 5.41 12.408 6.953 10.865z"
                transform="translate(-318 -166) translate(0 80) translate(288 58) translate(30 28)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CheckIcon.propTypes = {
  width: number,
  height: number,
};

CheckIcon.defaultProps = {
  width: 16,
  height: 16,
};

module.exports = CheckIcon;
