const React = require('react');
const { number } = require('prop-types');

const namespace = 'back-arrow-icon';

const BackArrowIcon = () => (
  <svg
    className={namespace}
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
  >
    <defs>
      <polygon
        id="chevron-left-a"
        points="6.061 8 10.53 12.47 9.47 13.53 3.939 8 9.47 2.47 10.53 3.53"
      />
    </defs>
    <use
      fillOpacity=".45"
      transform="translate(-4 -2)"
      href="#chevron-left-a"
    />
  </svg>
);

BackArrowIcon.propTypes = {
  width: number,
  height: number,
};

BackArrowIcon.defaultProps = {
  width: 16,
  height: 16,
};

module.exports = BackArrowIcon;
