const React = require('react');
const { Button } = require('@andes/button');
const { string, arrayOf, shape, bool, func } = require('prop-types');
const classNames = require('classnames');
const KycOnboardingIcon = require('../Icons/KycOnboardingIcon');
const KycOnboardingListIcon = require('../Icons/KycOnboardingIconList');

const namespace = 'kyc-onboarding';

const buildImage = (fromList) => {
  if (fromList) {
    return <KycOnboardingListIcon width={160} height={160} />;
  }
  return <KycOnboardingIcon width={160} height={160} />;
};

const defaultSetCallbackToUrl = (actions, kycCallback) => {
  const url = new URL(actions.primary.href);
  const params = url.searchParams;
  params.set('callback', kycCallback);
  url.search = params.toString();
  actions.primary.href = url.toString();
};

const kycOnboarding = ({
  title,
  subtitles,
  actions,
  fromList,
  kycCallback,
  siteId,
  setCallbackToUrl,
}) => {
  if (kycCallback) {
    (setCallbackToUrl || defaultSetCallbackToUrl)(actions, kycCallback);
  }

  const documentsId = {
    MLA: 'DNI',
    MLM: 'INE o IFE',
    MLC: 'RUT',
    MLB: 'CNH ou RG',
  };

  return (
    <div
      className={classNames(`${namespace}__wrapper`, {
        [`${namespace}__wrapper-list`]: fromList,
      })}
    >
      <div
        className={classNames('icon', {
          [`${namespace}__icon-list`]: fromList,
        })}
      >
        {' '}
        {buildImage(fromList)}{' '}
      </div>
      <h2
        className={classNames(`${namespace}__title`, {
          [`${namespace}__title-list`]: fromList,
        })}
      >
        {title}
      </h2>
      <ul
        className={classNames(`${namespace}__subtitles`, {
          [`${namespace}__subtitles-list`]: fromList,
        })}
      >
        {subtitles.map((item) => {
          const text = item.text(documentsId[siteId] || 'cédula');
          return (
            <li
              key={item.id}
              className={`${namespace}__subtitle`}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          );
        })}
      </ul>
      <div
        className={classNames(`${namespace}__actions`, {
          [`${namespace}__actions-list`]: fromList,
        })}
      >
        <Button key={actions.primary.id} {...actions.primary}>
          {' '}
          {actions.primary.label}{' '}
        </Button>
        {actions.secondary && (
          <Button key={actions.secondary.id} {...actions.secondary}>
            {' '}
            {actions.secondary.label}{' '}
          </Button>
        )}
      </div>
    </div>
  );
};

kycOnboarding.propTypes = {
  fromList: bool,
  title: string.isRequired,
  subtitles: arrayOf(string).isRequired,
  kycCallback: string,
  actions: shape({
    href: string,
    label: string,
    hierarchy: string,
    size: string,
  }).isRequired,
  setCallbackToUrl: func,
};

kycOnboarding.propTypes = {
  fromList: false,
  kycCallback: null,
  setCallbackToUrl: null,
};

module.exports = kycOnboarding;
