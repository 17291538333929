/* eslint-disable max-len */
const React = require('react');
const { number, func } = require('prop-types');

const namespace = 'check-icon';

const CloseIcon = ({ width, height, opacity, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-testid="close"
    className={namespace}
    onClick={onClick}
    width={width}
    height={height}
    viewBox={`0 0 ${height} ${width}`}
  >
    <g fill="none" fillOpacity={opacity} fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <g>
          <path
            d="M2 .938l6.002 6.003L14 .945l1.06 1.06-5.997 5.997L15.061 14 14 15.059 8.003 9.063 2 15.066l-1.06-1.06 6.002-6.004L.94 2 2 .939z"
            transform="translate(-320 -104) translate(320 104)"
          />
        </g>
      </g>
    </g>
  </svg>
);

CloseIcon.propTypes = {
  width: number,
  height: number,
  opacity: number,
  onClick: func,
};

CloseIcon.defaultProps = {
  width: 16,
  height: 16,
  opacity: 1,
  onClick: null,
};

module.exports = CloseIcon;
