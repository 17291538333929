const RESPONSES = {
  POSITIVE: 'si',
  NEGATIVE: 'no',
};

const ANSWERS = {
  POSITIVE_RESPONSE_SELLER:
    'La revisaremos para seguir mejorando tu experiencia.',
  NEGATIVE_RESPONSE_SELLER:
    'Seguiremos trabajando para que el vendedor te contacte lo antes posible y mejorar tu experiencia.',
  POSITIVE_RESPONSE_BUYER_LTR:
    'Gracias por ayudarnos a seguir mejorando tu experiencia.',
  NEGATIVE_RESPONSE_BUYER_LTR:
    'Le pediremos que te contacte lo antes posible para que puedas concretar tu visita.',
  FEEDBACK_RESPONSE_BUYER:
    'La revisaremos para seguir mejorando tu experiencia.',
};

const REASONS_LTR = {
  campaign_schedule_confirmed:
    'el asesor no te contactó para confirmar la visita',
  campaign_visit_fail:
    'realizaste la visita o no estás de acuerdo con las razones entregadas por el asesor',
  campaign_scheduling_canceled:
    'no estás de acuerdo con las razones por las que se canceló tu visita',
  campaign_schedule_expired:
    'no estás de acuerdo con las razones por las que se canceló tu visita',
  campaign_scheduling_modified: 'no solicitaste reagendar tu visita',
};

const URL_PATH = {
  BASE_PATH_LTR: '/ltr-buyer-feedback',
  MELIDATA_LTR_REPLIED: '/classifieds/ltr/replied',
  MELIDATA_LTR_FEEDBACK: '/classifieds/ltr/feedback',
  MELIDATA_LTR_FEEDBACK_SUBMIT: '/classifieds/ltr/feedback/submit',
};

module.exports = {
  RESPONSES,
  ANSWERS,
  REASONS_LTR,
  URL_PATH,
};
