const sitesCurrencyConfigs = {
  MLB: {
    locale: 'pt-br',
    currency: 'BRL',
  },
  MLM: {
    locale: 'es-mx',
    currency: 'MXN',
  },
  MLU: {
    locale: 'es-us',
    currency: 'USD',
  },
};

module.exports = sitesCurrencyConfigs;
