/* eslint-disable max-len */
const React = require('react');
const { number, func } = require('prop-types');

const KycOnboardingIcon = ({ width, height, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 159 160"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FEE800"
              fillOpacity=".8"
              d="M116.52 130.31s-6.808-20.835-38.342-20.835-38.342 20.835-38.342 20.835C24.5 118.573 14.594 99.973 14.594 79.028c0-35.501 28.47-64.278 63.584-64.278 35.118 0 63.584 28.777 63.584 64.278 0 20.945-9.908 39.549-25.242 51.282z"
              transform="translate(-561 -151) translate(562 152) translate(.116 .696)"
            />
            <path
              fill="#AFAFAF"
              fillRule="nonzero"
              d="M78.198 99.126c26.832 0 49.42 13.51 56.134 31.88-3.247 3.667-6.839 7.012-10.724 9.985 0 0-8.067-31.516-45.43-31.516-21.692 0-33.509 10.622-39.606 19.533-3.75-2.949-6.967-6.12-9.737-9.516 10.275-12.23 28.542-20.366 49.363-20.366z"
              opacity=".4"
              transform="translate(-561 -151) translate(562 152) translate(.116 .696)"
            />
            <path
              fill="#FFF"
              d="M104.074 58.728c0 14.462-6.622 37.658-25.898 37.658-19.272 0-25.893-23.196-25.893-37.658 0-14.46 11.595-26.177 25.893-26.177 14.303 0 25.898 11.718 25.898 26.177"
              transform="translate(-561 -151) translate(562 152) translate(.116 .696)"
            />
            <path
              stroke="#494848"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.79"
              d="M52.283 58.728c0 14.462 6.621 37.658 25.897 37.658 19.272 0 25.894-23.196 25.894-37.658 0-14.46-11.595-26.177-25.894-26.177-10.945 0-18.794 4.995-23.033 13.477"
              transform="translate(-561 -151) translate(562 152) translate(.116 .696)"
            />
            <path
              stroke="#494848"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3.491"
              d="M33.176 143.12c0-.254 0-.466.043-.682 2.572-18.58 21.708-32.984 44.94-32.984 23.268 0 42.408 14.404 44.98 32.984.043.216.043.428.043.682"
              transform="translate(-561 -151) translate(562 152) translate(.116 .696)"
            />
            <path
              stroke="#494848"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.79"
              d="M153.083 100.198c-4.85 17.56-15.58 32.634-29.901 42.922M33.176 143.12C13.366 128.885.42 105.49.42 79.023.42 35.596 35.244.44 78.16.44c33.79 0 62.55 21.756 73.282 52.18M155.056 67.146c.58 3.873.882 7.84.882 11.877"
              transform="translate(-561 -151) translate(562 152) translate(.116 .696)"
            />
          </g>
          <g>
            <path
              fill="#3483FA"
              d="M32.605 3.863l1.21 4.322 4.322 1.213c1.563.438 2.838 1.57 3.46 3.07.621 1.5.52 3.202-.275 4.618L39.123 21l2.196 3.91c.796 1.416.899 3.119.278 4.62-.621 1.5-1.897 2.633-3.46 3.072l-4.323 1.212-1.212 4.323c-.439 1.563-1.57 2.838-3.07 3.46-1.5.621-3.203.52-4.619-.275L21 39.123l-3.91 2.196c-1.416.796-3.119.899-4.62.278-1.5-.621-2.633-1.897-3.072-3.46l-1.212-4.323-4.323-1.212c-1.563-.439-2.838-1.57-3.46-3.07-.621-1.5-.52-3.203.275-4.619L2.877 21 .68 17.09c-.796-1.416-.899-3.119-.278-4.62.621-1.5 1.897-2.633 3.46-3.072l4.323-1.213 1.212-4.322c.44-1.563 1.57-2.837 3.07-3.459 1.499-.62 3.2-.52 4.616.273l3.913 2.2L24.907.68c1.224-.688 2.671-.863 4.024-.484 1.352.379 2.498 1.279 3.187 2.503.21.367.373.758.487 1.164z"
              transform="translate(-561 -151) translate(562 152) translate(103 116)"
            />
            <path
              fill="#FFF"
              d="M27.45 13.859l-8.908 8.913-3.994-4.004c-.242-.25-.576-.388-.924-.382-.347-.006-.681.132-.923.382l-1.85 1.844c-.249.242-.386.576-.38.923 0 .361.127.668.38.92l6.768 6.757c.242.248.576.385.923.379.347.006.681-.131.924-.38l11.683-11.664c.249-.243.386-.577.38-.924.005-.346-.132-.679-.38-.92l-1.85-1.847c-.242-.249-.576-.386-.923-.38-.35-.003-.684.134-.927.383z"
              transform="translate(-561 -151) translate(562 152) translate(103 116)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

KycOnboardingIcon.propTypes = {
  width: number,
  height: number,
  onClick: func,
};

KycOnboardingIcon.defaultProps = {
  width: 16,
  height: 16,
  onClick: null,
};

module.exports = KycOnboardingIcon;
