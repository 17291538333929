const sitesCurrencyConfigs = require('../../config/pricesLanding/sites-currency');

const getPriceAndCurrency = (data) => {
  const siteId = data?.parameters?.site_id;
  const currency = sitesCurrencyConfigs[`${siteId}`].currency.toLowerCase();
  const price = data?.suggested_prices?.[`${currency}`]?.estimated_price;

  return {
    currency,
    price,
  };
};

const getCurrencySymbol = (data, currencyId) => {
  const currency = data?.currencies.find(
    (elem) => elem.id.toLowerCase() === currencyId.toLowerCase(),
  );
  return currency?.symbol;
};

module.exports = {
  getPriceAndCurrency,
  getCurrencySymbol,
};
