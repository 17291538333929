const { ANSWERS, REASONS_LTR } = require('./constants');

const buildRedirectUrl = ({ baseUrl, platform: { id } }) => {
  const responseUrl = {
    '/ltr-buyer-response': {
      PI: 'https://www.portalinmobiliario.com',
      ML: 'https://www.mercadolibre.cl/c/inmuebles',
    },
    '/ltr-buyer-feedback': {
      PI: 'https://www.portalinmobiliario.com',
      ML: 'https://www.mercadolibre.cl/c/inmuebles',
    },
  };
  return responseUrl[baseUrl]?.[id] || '/';
};

const buildMessageBody = ({ baseUrl, answer }) => {
  const responseMap = {
    '/ltr-buyer-response': {
      si: ANSWERS.POSITIVE_RESPONSE_BUYER_LTR,
      no: ANSWERS.NEGATIVE_RESPONSE_BUYER_LTR,
    },
    '/vendedor-respondio': {
      si: ANSWERS.POSITIVE_RESPONSE_SELLER,
      no: ANSWERS.NEGATIVE_RESPONSE_SELLER,
    },
    '/ltr-buyer-feedback': {
      '': ANSWERS.FEEDBACK_RESPONSE_BUYER,
    },
  };
  return responseMap[baseUrl]?.[answer ?? ''] || null;
};

const buildReasonReject = ({ campaign }) => REASONS_LTR[campaign] || null;

module.exports = { buildRedirectUrl, buildMessageBody, buildReasonReject };
