// i18nLocalesExclusion:['pt-BR']
/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} = require('react');
const {
  buildRedirectUrl,
  buildMessageBody,
  buildReasonReject,
} = require('./utils');

const CongratsContext = createContext();

const CongratsProvider = ({ children, i18n }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getClassName = ({ baseUrl }) => (baseUrl === '/ltr-buyer-response'
    ? 'ltr-buyer-replied-congrats'
    : 'seller-replied-congrats');

  const makeContext = useCallback(() => {
    const common = window.__PRELOADED_STATE__;
    const settings = {
      feedbackForm: {
        namespace: 'congrats-feedback-form',
      },
      feedbackLtrForm: {
        namespace: 'congrats-ltr-feedback-form',
        reason: buildReasonReject(common),
        url: buildRedirectUrl(common),
      },
      feedbackCongrats: {
        namespace: getClassName(common),
        title: i18n.gettext('Recibimos tu respuesta'),
        description: i18n.gettext(buildMessageBody(common)),
        label: i18n.gettext('Ver propiedades'),
        url: buildRedirectUrl(common),
      },
    };
    setData({ ...settings, ...common });
  }, [setData, i18n]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      makeContext();
    }
    setLoading(false);
  }, [setLoading, makeContext]);
  return (
    <CongratsContext.Provider value={{ ...data, loading }}>
      {!loading && children}
    </CongratsContext.Provider>
  );
};

CongratsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: PropTypes.shape({}),
};
const useCongrats = () => useContext(CongratsContext);
module.exports = { CongratsProvider, useCongrats };
