/* eslint-disable max-len */
const React = require('react');
const { number, func } = require('prop-types');

const KycOnboardingListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="312"
    height="151"
    viewBox="0 0 312 151"
  >
    <defs>
      <rect
        id="99ithtdshc"
        width="100.533"
        height="60.375"
        x="0"
        y="0"
        rx="2"
      />
      <path id="gdocss4s0a" d="M0 0H312V138H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-24 -51) translate(24 51) translate(0 13)">
            <mask id="u2q7feea8b" fill="#fff">
              <use href="#gdocss4s0a" />
            </mask>
            <g mask="url(#u2q7feea8b)">
              <g>
                <g>
                  <g fill="#EEE" fillRule="nonzero">
                    <g>
                      <g>
                        <g>
                          <path
                            d="M15.097 44.657c.194.201.21.512.04.732-1.921 2.468-4.55 2.753-7.67.89-.266-.158-.353-.503-.193-.768.16-.266.507-.352.773-.193 2.476 1.479 4.375 1.393 5.873-.23-1.463-1.264-3.175-1.28-5.273-.026-.466.28-1.019-.196-.81-.696 1.296-3.094 1.725-6.327 1.288-9.708-.043-.335.22-.632.56-.632h2.955c.237 0 .44-.156.482-.303l3.591-31.609c.036-.143.003-.296-.091-.413-.097-.12-.246-.191-.404-.191H1.897c-.158 0-.308.07-.404.19-.094.118-.127.27-.078.487l3.578 31.536c.035.308-.188.586-.497.62-.309.035-.588-.186-.623-.494L.308 2.385C.188 1.902.3 1.392.612 1.002c.31-.388.785-.613 1.285-.613h14.32c.501 0 .975.225 1.286.613.312.39.424.9.317 1.31l-3.591 31.61c-.181.72-.838 1.225-1.59 1.225h-2.324c.284 2.861-.025 5.629-.926 8.297 2.166-.85 4.103-.452 5.708 1.213z"
                            transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(217.576 .51) translate(1.733 70.216) rotate(-180 9.06 23.938)"
                          />
                          <path
                            d="M13.469 7.53c-1.022-.799-2.207-.774-3.674.094-.068.04-.141.065-.216.075 1.628.893 2.88.83 3.89-.168zm-4.446-.1c-.159-.267-.071-.61.196-.77 2.08-1.232 3.951-1.096 5.443.437.196.201.212.515.04.735-1.488 1.894-3.537 2.115-5.932.697-.268-.158-.356-.502-.197-.768.106-.178.296-.276.49-.275-.015-.018-.028-.037-.04-.057z"
                            transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(217.576 .51) translate(1.733 70.216) matrix(1 0 0 -1 0 15.277)"
                          />
                        </g>
                        <g>
                          <path
                            d="M36.57 3.365c.272.15.37.492.218.762s-.495.368-.767.217C32.59 2.438 28.72 1.424 24.7 1.424 11.848 1.423 1.43 11.79 1.43 24.58S11.848 47.74 24.7 47.74s23.27-10.368 23.27-23.158c0-3.82-.93-7.505-2.684-10.807-.145-.274-.04-.613.235-.758.275-.145.616-.04.761.234 1.84 3.463 2.815 7.328 2.815 11.331 0 13.41-10.923 24.28-24.397 24.28C11.226 48.86.303 37.99.303 24.58.303 11.172 11.226.301 24.7.301c4.213 0 8.271 1.065 11.87 3.064z"
                            transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(217.576 .51)"
                          />
                          <path
                            d="M36.4 25.19c.311 0 .563.251.563.56 0 .31-.252.562-.563.562h-13c-.311 0-.563-.251-.563-.561V6.9c0-.31.252-.56.563-.56.311 0 .563.25.563.56v18.29H36.4z"
                            transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(217.576 .51)"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          d="M10.963 29.886v13.239c0 9.694 7.897 17.552 17.637 17.552h6.933c9.74 0 17.637-7.858 17.637-17.552v-13.8c0-.31.252-.56.563-.56.311 0 .564.25.564.56v13.8c0 10.313-8.401 18.673-18.764 18.673H28.6c-10.363 0-18.763-8.36-18.763-18.673v-13.8c0-.31.252-.56.563-.56h18.2c.311 0 .563.25.563.56 0 .31-.252.56-.563.56H10.963zM33.312.583c.154-.268.499-.36.769-.207.27.155.362.497.207.766-2.212 3.833-2.212 7.708.005 11.7 2.407 4.336 2.407 8.511-.012 12.446-.163.264-.51.347-.775.186-.265-.162-.349-.507-.187-.771 2.203-3.583 2.203-7.328-.012-11.318C30.9 9.052 30.9 4.76 33.312.583zM28.974 13.385c-.15-.271-.052-.612.22-.762.273-.15.615-.052.766.22 2.407 4.335 2.407 8.51-.013 12.445-.162.264-.509.347-.774.186-.266-.162-.35-.507-.187-.771 2.203-3.583 2.203-7.328-.012-11.318z"
                          transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(0 56.58)"
                        />
                        <path
                          d="M12.003 35.794c0-.31.253-.56.564-.56.31 0 .563.25.563.56v3.627c0 3.412-2.678 5.558-6.387 5.558H4.524c-3.71 0-6.387-2.146-6.387-5.558v-3.627c0-.31.252-.56.563-.56.311 0 .563.25.563.56v3.627c0 2.733 2.127 4.437 5.261 4.437h2.219c3.134 0 5.26-1.704 5.26-4.437v-3.627z"
                          transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(0 56.58) rotate(90 5.633 40.106)"
                        />
                      </g>
                      <g>
                        <path
                          d="M5.173 14.964c.31 0 .563.251.563.561 0 .31-.252.56-.563.56H1.94c-.159 0-.308.072-.404.192-.094.117-.128.27-.092.413l3.578 14.287c.055.218.259.375.496.375h13.231c.237 0 .44-.157.496-.375l3.578-14.287c.036-.144.002-.296-.092-.413-.096-.12-.245-.191-.404-.191h-7.86c-.312 0-.564-.251-.564-.561 0-.31.252-.56.563-.56h7.86c.501 0 .975.225 1.286.613.312.39.425.9.304 1.383l-3.578 14.287c-.18.72-.838 1.225-1.589 1.225H5.518c-.752 0-1.408-.504-1.59-1.225L.352 16.96c-.121-.483-.009-.993.304-1.383.31-.388.784-.614 1.285-.614h3.233z"
                          transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(56.333 85.905)"
                        />
                        <path
                          d="M6.467 10.077l.155.599-.614.095c-3.257.506-4.408 1.922-3.73 4.45.08.299-.099.606-.4.686-.3.08-.609-.098-.689-.397-.82-3.06.61-5.03 4.055-5.74-.42-2.293.617-3.139 3.3-3.76l.573-.131c.206-.049.379-.091.544-.134 2.531-.666 3.642-1.836 3.642-4.883 0-.31.253-.56.564-.56.31 0 .563.25.563.56 0 3.609-1.494 5.18-4.481 5.967-.176.046-.359.09-.574.141-.126.03-.647.149-.57.131-2.37.546-2.85 1.004-2.338 2.976z"
                          transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(56.333 85.905)"
                        />
                        <path
                          d="M19.028 15.273c.051.305-.156.594-.462.645-.307.051-.598-.155-.65-.46-.634-3.768 1.02-5.801 4.744-5.801.311 0 .564.25.564.56 0 .31-.253.561-.564.561-3.015 0-4.153 1.399-3.632 4.495z"
                          transform="translate(-.6 -3.45) translate(.6 2.587) translate(15.6) translate(56.333 85.905) matrix(-1 0 0 1 41.008 0)"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    stroke="#EEE"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                    d="M0 117.731L104 117.731M208.867 117.731L312 117.731"
                    transform="translate(-.6 -3.45) translate(.6 2.587)"
                  />
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        fill="#FFF"
                        d="M45.611 78.529v28.613H6.784v-30.07C3.814 69.01 1.733 62.002.541 56.045-1.248 47.11 1.9 42.53 2.756 35.843c.855-6.687 11.832 1.09 20.071-8.73C28.32 20.567 36.467 11.53 47.267 0c8.393 17.2 12.76 26.643 13.1 28.326.34 1.683.34 4.188 0 7.517L45.61 78.529z"
                        transform="translate(-.6 -3.45) translate(109.6) translate(26.17 .627) translate(10.942 35.228)"
                      />
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M46.893.157L59.5 25.917c1.015 2.047 1.69 5.733 1.015 8.406-3.487 9.367-8.473 24.151-14.957 44.352v26.765M6.79 88.703v-11.63C4.637 69.724.933 61.834.54 56.044c-.391-5.79.411-19.88 5.022-22.55 4.611-2.67 10.505-.024 14.714-4.502.125-.133 2.732.19 7.82.97"
                        transform="translate(-.6 -3.45) translate(109.6) translate(26.17 .627) translate(10.942 35.228)"
                      />
                      <path
                        stroke="#333"
                        strokeLinecap="round"
                        strokeWidth="1.042"
                        d="M6.858 92.635L6.858 96.038"
                        transform="translate(-.6 -3.45) translate(109.6) translate(26.17 .627) translate(10.942 35.228)"
                      />
                    </g>
                    <path
                      fill="#000"
                      fillOpacity=".15"
                      fillRule="nonzero"
                      d="M25.051 18.855l40.957 33.44V73.87c0 2.918-2.02 5.448-4.864 6.095l-28.457 6.47c-3.366.766-6.715-1.343-7.48-4.708-.104-.455-.156-.92-.156-1.386V18.855z"
                      transform="translate(-.6 -3.45) translate(109.6) translate(26.17 .627)"
                    />
                    <path
                      fill="#FFF"
                      fillRule="nonzero"
                      stroke="#000"
                      strokeWidth="1.5"
                      d="M6.795 7.675l-.14.237C5.518 9.917 5.22 12.188 5.673 14.3c.451 2.11 1.651 4.062 3.509 5.426h0l9.322 6.848c1.563 1.149 3.444 1.555 5.22 1.283 1.778-.272 3.451-1.222 4.6-2.786.163-.222.313-.453.449-.693 1.137-2.005 1.434-4.276.982-6.388-.452-2.111-1.652-4.062-3.51-5.427h0l-9.321-6.847C15.36 4.567 13.48 4.16 11.703 4.433c-1.777.272-3.45 1.222-4.599 2.785-.163.223-.313.454-.45.694z"
                      transform="translate(-.6 -3.45) translate(109.6) translate(26.17 .627)"
                    />
                  </g>
                  <g transform="translate(-.6 -3.45) translate(109.6) translate(.2 12.075)">
                    <rect width="106.6" height="64.688" fill="#FFF" rx="2" />
                    <g transform="translate(2.6 2.587)">
                      <use
                        fill="#D7F4FF"
                        fillOpacity=".14"
                        href="#99ithtdshc"
                      />
                    </g>
                    <path
                      stroke="#333"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M90.785 0h10.342C102.714 0 104 1.281 104 2.862V60.1c0 1.58-1.286 2.861-2.873 2.861H57.46m-6.895 0H2.873C1.286 62.962 0 61.682 0 60.102V28.047m0-6.869V2.862C0 1.282 1.286 0 2.873 0h75.845"
                    />
                    <path
                      fill="#FFF"
                      d="M36.4 33.418v15.554c0 1.534-1.245 2.778-2.78 2.778H9.713c-1.535 0-2.78-1.244-2.78-2.778v-27.22c0-1.533 1.245-2.777 2.78-2.777H33.62c1.535 0 2.78 1.244 2.78 2.778v4.444"
                    />
                    <g>
                      <g>
                        <path
                          fill="#F4D60F"
                          fillRule="nonzero"
                          d="M7.87 5.514c.54.634 6.497 1.738 8.937-2.292.983-1.413 2.424-.648 4.323 2.297-.578-3.698-2.9-5.464-6.963-5.298-6.096.25-6.524 4.762-6.298 5.293z"
                          transform="translate(7.8 23.288)"
                        />
                        <g
                          stroke="#333"
                          strokeLinecap="round"
                          strokeWidth=".875"
                        >
                          <path
                            d="M16.696 15.907c0 1.331.271 1.011 1.068 1.388l-3.655 3.542-3.5-3.542c.607-.748.607-.377.724-1.388"
                            transform="translate(7.8 23.288) translate(.54)"
                          />
                          <path
                            fill="#FFF"
                            d="M28.049 28.186c.295-4.714-.772-7.675-3.202-8.882-2.55-1.267-5.019-2.08-7.406-2.44-1.79 2.122-2.905 3.183-3.347 3.183-.442 0-1.58-1.06-3.415-3.183-2.59.618-5.008 1.431-7.254 2.44-2.247 1.009-3.37 2.856-3.37 5.542M7.129 10.518c-.5-.128-.85-.58-.85-1.096v-1.25c0-.486.394-.88.88-.88"
                            transform="translate(7.8 23.288) translate(.54)"
                          />
                          <path
                            fill="#FFF"
                            d="M21.567 10.518c-.5-.128-.85-.58-.85-1.096v-1.25c0-.486.394-.88.88-.88"
                            transform="translate(7.8 23.288) translate(.54) matrix(-1 0 0 1 42.315 0)"
                          />
                          <path
                            d="M10.157.942c1.015-.53 2.289-.795 3.821-.795 4.014 0 6.254 1.817 6.72 5.451.002.02.004.04.004.06v4.626c0 .185-.108.344-.264.42-.527 3.258-3.249 5.74-6.527 5.74-3.317 0-6.065-2.543-6.545-5.858-.069-.082-.11-.187-.11-.302-.075-2.125-.075-3.677 0-4.656.11-1.469.573-2.249.865-2.75"
                            transform="translate(7.8 23.288) translate(.54)"
                          />
                          <path
                            fill="#FFF"
                            d="M5.097 23.331L5.097 27.639M22.841 23.096L22.841 27.573"
                            transform="translate(7.8 23.288) translate(.54)"
                          />
                        </g>
                        <path
                          fillRule="nonzero"
                          stroke="#333"
                          strokeWidth=".875"
                          d="M7.823 6.015c3.78 1.571 6.736.707 8.869-2.593.873-1.174 2.38-.31 4.52 2.593"
                          transform="translate(7.8 23.288)"
                        />
                      </g>
                      <path
                        fill="#F4D60F"
                        stroke="#333"
                        strokeLinejoin="round"
                        strokeWidth=".875"
                        d="M20.795 11.526c.168.687.814 1.59 1.936 2.705-1.683.558-2.946.698-3.788.419.814-.793 1.431-1.835 1.852-3.124zM8.04 11.526c-.168.687-.814 1.59-1.936 2.705 1.684.558 2.946.698 3.788.419-.813-.793-1.43-1.835-1.852-3.124z"
                        transform="translate(7.8 23.288)"
                      />
                    </g>
                    <path
                      stroke="#333"
                      strokeLinecap="round"
                      strokeWidth="1.25"
                      d="M37.267 35.143v15.554c0 1.534-1.245 2.778-2.78 2.778H10.58c-1.535 0-2.78-1.244-2.78-2.778v-27.22c0-1.533 1.245-2.777 2.78-2.777h23.907c1.535 0 2.78 1.244 2.78 2.778v4.444"
                    />
                    <rect
                      width="11.267"
                      height="2.587"
                      x="19.067"
                      y="10.35"
                      fill="#F4D60F"
                      opacity=".4"
                      rx="1.294"
                    />
                    <rect
                      width="12.133"
                      height="1.725"
                      x="43.333"
                      y="18.975"
                      fill="#F4D60F"
                      opacity=".4"
                      rx=".863"
                    />
                    <rect
                      width="34.667"
                      height="2.587"
                      x="43.333"
                      y="42.263"
                      fill="#F4D60F"
                      opacity=".4"
                      rx="1.294"
                    />
                    <rect
                      width="34.667"
                      height="2.587"
                      x="43.333"
                      y="47.438"
                      fill="#F4D60F"
                      opacity=".4"
                      rx="1.294"
                    />
                    <rect
                      width="32.067"
                      height="2.587"
                      x="58.933"
                      y="12.075"
                      fill="#F4D60F"
                      opacity=".4"
                      rx="1.294"
                    />
                    <rect
                      width="37.267"
                      height="2.587"
                      x="58.933"
                      y="8.625"
                      fill="#F4D60F"
                      opacity=".4"
                      rx="1.294"
                    />
                    <ellipse
                      cx="11.267"
                      cy="11.213"
                      fill="#F4D60F"
                      opacity=".4"
                      rx="4.333"
                      ry="4.313"
                    />
                    <rect
                      width="30.333"
                      height="2.587"
                      x="43.333"
                      y="23.288"
                      fill="#F4D60F"
                      rx="1.294"
                    />
                    <rect
                      width="23.4"
                      height="2.587"
                      x="43.333"
                      y="29.325"
                      fill="#F4D60F"
                      rx="1.294"
                    />
                    <rect
                      width="27.733"
                      height="2.587"
                      x="43.333"
                      y="34.5"
                      fill="#F4D60F"
                      rx="1.294"
                    />
                  </g>
                  <path
                    fill="#FFF"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M61.789 110.576c-9.806-2.747-14.709-10.928-14.709-24.543L35.535 73.18c-8.778-8.838-8.233-14.726-4.717-17.914 3.952-3.583 8.982-1.086 12.575 2.867l28.741 29.744"
                    transform="translate(-.6 -3.45) translate(109.6)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#3483FA"
              d="M32.605 3.863l1.21 4.322 4.322 1.213c1.563.438 2.838 1.57 3.46 3.07.621 1.5.52 3.202-.275 4.618L39.123 21l2.196 3.91c.796 1.416.899 3.119.278 4.62-.621 1.5-1.897 2.633-3.46 3.072l-4.323 1.212-1.212 4.323c-.439 1.563-1.57 2.838-3.07 3.46-1.5.621-3.203.52-4.619-.275L21 39.123l-3.91 2.196c-1.416.796-3.119.899-4.62.278-1.5-.621-2.633-1.897-3.072-3.46l-1.212-4.323-4.323-1.212c-1.563-.439-2.838-1.57-3.46-3.07-.621-1.5-.52-3.203.275-4.619L2.877 21 .68 17.09c-.796-1.416-.899-3.119-.278-4.62.621-1.5 1.897-2.633 3.46-3.072l4.323-1.213 1.212-4.322c.44-1.563 1.57-2.837 3.07-3.459 1.499-.62 3.2-.52 4.616.273l3.913 2.2L24.907.68c1.224-.688 2.671-.863 4.024-.484 1.352.379 2.498 1.279 3.187 2.503.21.367.373.758.487 1.164z"
              transform="translate(-24 -51) translate(24 51) translate(193)"
            />
            <path
              fill="#FFF"
              d="M27.45 13.859l-8.908 8.913-3.994-4.004c-.242-.25-.576-.388-.924-.382-.347-.006-.681.132-.923.382l-1.85 1.844c-.249.242-.386.576-.38.923 0 .361.127.668.38.92l6.768 6.757c.242.248.576.385.923.379.347.006.681-.131.924-.38l11.683-11.664c.249-.243.386-.577.38-.924.005-.346-.132-.679-.38-.92l-1.85-1.847c-.242-.249-.576-.386-.923-.38-.35-.003-.684.134-.927.383z"
              transform="translate(-24 -51) translate(24 51) translate(193)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

KycOnboardingListIcon.propTypes = {
  width: number,
  height: number,
  onClick: func,
};

KycOnboardingListIcon.defaultProps = {
  width: 16,
  height: 16,
  onClick: null,
};

module.exports = KycOnboardingListIcon;
