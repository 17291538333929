const React = require('react');
const { number } = require('prop-types');

const namespace = 'lock-icon';

const LockIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={namespace}
    width={width}
    height={height}
    viewBox={`0 0 ${height} ${width}`}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#3483FA" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M12 .75c2.9 0 5.252 2.351 5.252 5.252v2.981l2.211.001V18c0 2.9-2.35 5.25-5.25 5.25H9.787c-2.9 0-5.25-2.35-5.25-5.25V8.984l2.21-.001.001-2.981c0-2.828 2.235-5.134 5.036-5.248zm5.963 9.734H6.037V18c0 2.071 1.679 3.75 3.75 3.75h4.426c2.071 0 3.75-1.679 3.75-3.75v-7.516zM12 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-11.25c-2.072 0-3.752 1.68-3.752 3.752v2.981h7.504V6.002c0-2.005-1.573-3.643-3.553-3.747z"
                transform="translate(-300 -150) translate(0 80) translate(288 58) translate(12 12)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LockIcon.propTypes = {
  width: number,
  height: number,
};

LockIcon.defaultProps = {
  width: 16,
  height: 16,
};

module.exports = LockIcon;
