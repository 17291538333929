const React = require('react');
const { Button } = require('@andes/button');
const Message = require('@andes/message');
const classnames = require('classnames');
const { string, arrayOf, shape, bool } = require('prop-types');
const IconClose = require('../Icons/CloseIcon');
const CheckIcon = require('../Icons/CheckIcon');
const LockIcon = require('../Icons/LockIcon');

const onCloseHandler = (e) => {
  if (e) { e.preventDefault(); }
  window.history.back();
};

const buildCheckIcon = (className) => (
  <div className={`${className}__icon-check`}>
    <div className={`${className}__check-container`}><CheckIcon width={24} height={24} /> </div>
    <LockIcon width={24} height={24} />
  </div>
);

const kycCongrats = ({ className, title, subtitle, tooltip, actions, buttonSize, fromList, isMobile, webView }) => (
  <div className={`${className}__wrapper`}>
    <div className={`${className}__header`}>
      { isMobile && !webView
      && <div className={`icon ${className}__close`}>
        <IconClose width={16} height={16} opacity={1} onClick={onCloseHandler} />
      </div>}

      <div className={`${className}__header-content`}>
        <div className={`${className}__texts`}>
          <span className={`${className}__title`}>{title}</span>
          <span className={`${className}__subtitle`}>{subtitle}</span>
        </div>
        { buildCheckIcon(className) }
      </div>
    </div>
    <div className={`${className}__tooltip`}>
      <span className={`${className}__tooltip-title`}>{tooltip.title}</span>
      { tooltip.content && <Message title={tooltip.content} color="accent" hierarchy="quiet" />}
      <div
        key={className}
        className={classnames(`${className}__actions`, { [`${className}__actions-list`]: fromList })}
      >
        { actions.map(action => (
          <Button key={action.id} size={buttonSize} {...action}>
            {action.label}
          </Button>
        ))}
      </div>
    </div>
  </div>
);

kycCongrats.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  buttonSize: string,
  isMobile: bool,
  tooltip: shape({
    title: string,
    content: string,
  }).isRequired,
  actions: arrayOf(shape({
    href: string,
    label: string,
    hierarchy: string,
    size: string,
  })).isRequired,
};

kycCongrats.defaultProps = {
  buttonSize: 'small',
  isMobile: false,
};

module.exports = kycCongrats;
